import { critical } from 'services/ApplicationInsightsLog';
import {
	CheckBoxList,
	DateTimePicker,
	GoogleMapSearch,
	IframeComponent,
	MitIdButton,
	RadioList,
	RichText,
	StateList,
	TextInput,
} from './index';

export const Component: React.FC<any> = (props) => {
	const components = {
		MitIdButton: MitIdButton,
		InputComponent: TextInput,
		GoogleMapsComponent: GoogleMapSearch,
		DropDownListComponent: () => <p>DropDownListComponent</p>,
		DateTimeComponent: DateTimePicker,
		InputListComponent: () => <p>InputListComponent</p>,
		GridListComponent: () => <p>GridListComponent</p>,
		ButtonListComponent: () => <p>ButtonListComponent</p>,
		RadioListComponent: RadioList,
		CheckboxListComponent: CheckBoxList,
		StateListComponent: StateList,
		RichTextComponent: RichText,
		FileUploadComponent: () => <p>FileUploadComponent</p>,
		IframeComponent: IframeComponent,
	};

	const DynamicComponent = components[props?.componentType as keyof typeof components];

	if (typeof DynamicComponent === 'undefined') {
		// TODO: Should it break in build?s
		critical(
			new Error(
				`Component [${props.componentType}] is missing a React Component. Check /components/Component.tsx`
			)
		);
		return (
			<div>{`Component [${props.componentType}] is missing a React Component. Check /components/Component.tsx`}</div>
		);
	}

	return <DynamicComponent {...props} />;
};
