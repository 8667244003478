import { useRef } from 'react';
import styles from './TransitionContainer.module.scss';

export interface TransitionContainerProps {
	animate: boolean;
	children: React.ReactNode;
}

export const TransitionContainer: React.FC<TransitionContainerProps> = ({ animate, children }) => {
	const myRef = useRef<HTMLDivElement>(null);

	if (animate) {
		setTimeout(() => {
			if (!myRef?.current) return;
			const element = myRef.current;
			element?.scrollIntoView({ behavior: 'smooth', block: 'start' }); // start, end
		}, 250);
	}

	return (
		<div
			className={styles.TransitionContainer}
			ref={myRef}
		>
			{children}
		</div>
	);
};
