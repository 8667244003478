import { store } from 'store/store';
import { apiInstance } from './Api';

export const getClaimFlow = (claimId: number): Promise<WizardModel> => {
	return apiInstance().get(`/webapi/WizardUi/AppData/?blockLocalId=${claimId}`);
};

export const getClaimFlowContent = (claimFlowName: string): Promise<ContentFlowData> => {
	return apiInstance().get(`/api/ab/v3/content?contentUrl=${claimFlowName}&matchExact=true`);
};

export const postDataSource = (
	dataSourceUrl: string,
	pageId?: number,
	params?: { [key: string]: string }
): Promise<DataSourceResponse> => {
	const { claimForm, customerParty } = store.getState();
	const partyData = customerParty.data;

	const formData = Object.keys(claimForm).reduce((acc: any, key) => {
		const value = claimForm[key].data;

		if (Array.isArray(value)) {
			// Clone the array to avoid mutating the original one
			acc[key] = [...value];
		} else if (typeof value === 'object' && value !== null) {
			// Clone the object to avoid mutating the original one
			acc[key] = { ...value };
		} else {
			// For primitive values (like string, number, etc.), just assign them directly
			acc[key] = value;
		}

		return acc;
	}, {});

	return apiInstance().post(
		dataSourceUrl,
		{
			pageId: pageId,
			fieldValues: {
				...partyData,
				...formData,
			},
		},
		{
			params: { ...params, datasourceId: params?.datasourceId },
		}
	);
};
