import { useEffect } from 'react';
import { refreshUsabilla } from 'services/UsabillaService';
import styles from './Usabilla.module.scss';

export interface UsabillaProps {
	stepId: string;
	usabillaWidgetId?: string;
	category?: string;
}

export const Usabilla: React.FC<UsabillaProps> = ({ stepId, usabillaWidgetId, category }) => {
	useEffect(() => {
		refreshUsabilla(stepId);
	}, []);

	return (
		<div
			className={styles.Usabilla}
			ub-in-page={usabillaWidgetId}
			ub-in-page-category={category}
		/>
	);
};
