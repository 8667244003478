import classNames from 'classnames';

import { ContentContainer, MitIdButton } from 'components';
import { Component } from 'components/Component';
import { PageNames } from 'constants/pageNames';
import { usePageParam } from 'hooks/usePageParam';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { verifyUserLogin } from 'store/slices/customerPartySlice';
import { evaluateConditions, executeCondition } from 'utilities/conditionalFieldsValidator';
import styles from './LoginPage.module.scss';

export interface LoginPageProps {}

export const LoginPage: React.FC<LoginPageProps> = () => {
	const { setPageParam } = usePageParam();

	const dispatch = useAppDispatch();

	const claimContent = useAppSelector((state) => state.claimContent);

	const isUserLoggedIn = useAppSelector((state) => state.customerParty?.data?.isUserLoggedIn);

	const { pages: wizardPages, mitIdConfig } = claimContent.wizardData ?? {};

	const { heading, intro, content, conditionalFields, name } =
		wizardPages?.find((page) => page.path === '/' + PageNames.LoginPage) ?? {};

	const { connectionId, applicationId } = mitIdConfig?.almbrand ?? {};

	const findMatchingCondition = (componentFieldName: string) => {
		if (!conditionalFields) return;

		return conditionalFields.find((condition) => {
			return evaluateConditions(componentFieldName, condition);
		});
	};

	useEffect(() => {
		if (isUserLoggedIn) {
			setPageParam(PageNames.StartPage);
		} else {
			dispatch(verifyUserLogin());
		}
	}, [isUserLoggedIn]);

	return (
		<section
			className={classNames(styles.LoginPage, styles.LoginPage__enter)}
			id={name}
		>
			<ContentContainer
				children={intro}
				headingText={heading}
			/>

			{content?.map((component, index) => {
				const { actionStr } = findMatchingCondition(component.fieldName) ?? {};

				return actionStr ? (
					executeCondition(actionStr) && (
						<Component
							key={component?.inputId}
							{...component}
						/>
					)
				) : (
					<Component
						key={component?.inputId}
						{...component}
					/>
				);
			})}

			<div className={styles.LoginPage__navigationButtons}>
				{mitIdConfig && (
					<MitIdButton
						connectionId={connectionId}
						applicationId={applicationId}
					>
						Log ind og anmeld skade
					</MitIdButton>
				)}
			</div>
		</section>
	);
};
