import { useEffect, useState } from 'react';

export const useToggleClassOnScoll = (offset: number) => {
	const [addClass, setAddClass] = useState(false);
	const [position, setPosition] = useState(window.scrollY);
	const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');

	useEffect(() => {
		const scrollEvent = () => {
			const scollTop = window.scrollY;

			//Don't hide header on max scroll up
			if (scollTop < 0) return false;

			if (position < scollTop) {
				setScrollDirection('down');
			} else {
				setScrollDirection('up');
			}

			if (scrollDirection === 'down' && position + offset <= scollTop) {
				setAddClass(true);
				setPosition(scollTop);

				return false;
			}

			if (scrollDirection === 'up' && position - offset >= scollTop) {
				setAddClass(false);
				setPosition(scollTop);

				return false;
			}
		};

		//Remove class if screen is desktop
		const checKResize = () => {
			if (window.innerWidth > 1023) {
				console.log('Window width: ', window.innerWidth);
				setAddClass(false);

				return false;
			}
		};

		window.addEventListener('resize', checKResize);
		window.addEventListener('scroll', scrollEvent);

		return () => {
			window.removeEventListener('scroll', scrollEvent);
			window.removeEventListener('resize', checKResize);
		};
	}, [position, scrollDirection, addClass]);

	return addClass;
};
