import { DatePicker } from '@almbrand/datepicker';
import { ThemeContext } from '@almbrand/themeselector';
import { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

export interface DateTimePickerProps extends WizardComponentBase {
	register: any;
	placeholder: string;
	value: string;
	dateTimeType: 'date' | 'datetime';
	timeLabel: string;
	minValueString: string;
	maxValueString: string;
	hasError?: boolean;
}
export const DateTimePicker: React.FC<DateTimePickerProps> = ({
	register,
	required,
	fieldName = '',
	validationErrorFormatText,
	hasError,
	dateTimeType,
	placeholder,
	minValueString,
	maxValueString,
	timeLabel,
}) => {
	const theme = useContext(ThemeContext);
	const { onChange, name } = register(fieldName, { required });
	const fieldWatch = useAppSelector((store) => store.claimForm[fieldName]);
	const customerPartyValue = useAppSelector((store) => store.customerParty.data[fieldName]);
	const [value, setValue] = useState<Date | undefined>(undefined);

	useEffect(() => {
		const dateItem = fieldWatch?.data ?? customerPartyValue;

		if (dateItem?.value) {
			const parsedDate = new Date(dateItem.value);
			if (!isNaN(parsedDate.getTime())) {
				handleChange(parsedDate);
				setValue(parsedDate);
			}
		}
	}, [fieldWatch, customerPartyValue]);

	const handleChange = (data: Date | undefined) => {
		onChange({
			target: {
				name: name,
				value: { value: data },
			},
		});
	};
	return (
		<DatePicker
			icon={{
				iconProp: { themeName: theme, icon: 'calendar' },
			}}
			onDateChange={(data) => handleChange(data)}
			value={value && new Date(value)}
			errorMessage={validationErrorFormatText}
			hasError={hasError}
			displayTimeSelector={dateTimeType == 'datetime'}
			placeholder={placeholder}
			fromDate={new Date(minValueString)}
			toDate={new Date(maxValueString)}
			selectTimeText={timeLabel}
		/>
	);
};
