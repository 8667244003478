import { SystemIcons } from '@almbrand/systemicons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styles from './StepIndicator.module.scss';

export interface StepIndicatorProps {
	className?: string;
	currentStepIndex: number;
	// steps: string[];
	longSteps: number[];
	steps: {
		value: string;
		title: string;
		index?: number | null;
	}[];
	getLiIndex: (
		index: number | null | undefined,
		event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
	) => void;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({
	className,
	currentStepIndex,
	steps,
	getLiIndex,
	longSteps,
}) => {
	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const addClass = useAppSelector((state) => state.addCssClass);

	useEffect(() => {
		const handleResize = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener('resize', handleResize);
		return () => removeEventListener('resize', handleResize);
	}, []);

	const verticalStepClass = (index: number): string => {
		return !longSteps.includes(index) ? styles.StepIndicator_verticalLine : styles.StepIndicator_verticalLine_long;
	};

	return (
		<div className={classNames(styles.StepIndicator, className)}>
			{dimensions.width > 1040 && dimensions.height > 830 ? (
				<ol className={classNames(styles.StepIndicator_list, styles.StepIndicator___desktop)}>
					{steps?.map((step, index) => (
						<div
							style={{ height: longSteps.includes(index) ? 242 : 121 }}
							key={index + 'step'}
						>
							<li
								className={classNames(
									styles.StepIndicator_item,
									currentStepIndex > index && styles.StepIndicator_item___complete,
									currentStepIndex === index && styles.StepIndicator_item___current,
									currentStepIndex < index && styles.StepIndicator_item___disabled
								)}
								aria-current={currentStepIndex === index ? 'step' : false}
								onClick={(e) => getLiIndex(step.index, e)}
								onKeyDown={(event) => {
									if (event.key === 'Enter' || event.key === ' ') {
										getLiIndex(index, event);
									}
								}}
							>
								{currentStepIndex >= index ? (
									<span className={styles.StepIndicator_itemCircle}>
										<SystemIcons
											className={styles.StepIndicator_itemCheckMark}
											themeName='Codan-theme'
											icon='Checkmark_Blue_with_stroke'
											height={34} // corresponds to 20px(???)
											width={34}
										/>
									</span>
								) : (
									<span className={styles.StepIndicator_itemCircle_disabled} />
								)}
								<p className={styles.StepIndicator_itemText}>{step.title}</p>
							</li>

							{index !== steps.length - 1 && (
								<div
									className={classNames(currentStepIndex > index && verticalStepClass(index))}
									data-index={index}
								/>
							)}
						</div>
					))}
				</ol>
			) : (
				<ol
					className={classNames(
						styles.StepIndicator_list_mobile,
						styles.StepIndicator___mobile,
						addClass.addClass ? styles.StepIndicator_list_mobile___hide : null
					)}
				>
					{steps?.map((step, index) => (
						<li
							key={index + 'stepMobile'}
							aria-current={currentStepIndex === index ? 'step' : false}
							onClick={(e) => getLiIndex(step.index, e)}
							className={classNames(
								styles.StepIndicator_item_mobile,
								index === 2 && styles.StepIndicator_item_mobile___long
							)}
							onKeyDown={(event) => {
								if (event.key === 'Enter' || event.key === ' ') {
									getLiIndex(index, event);
								}
							}}
						>
							<div
								className={classNames(
									currentStepIndex > index && styles.StepIndicator_item_mobile___complete,
									currentStepIndex === index && styles.StepIndicator_item_mobile___current
								)}
							/>
						</li>
					))}
				</ol>
			)}
		</div>
	);
};
