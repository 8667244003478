import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface GlobalModalState {
	isModal: boolean;
}

const initialState: GlobalModalState = {
	isModal: false,
};

export const modalSlice = createSlice({
	name: 'ModalSlice',
	initialState,
	reducers: {
		setGlobalModal: (state, action: PayloadAction<boolean>) => {
			const modal = action.payload;

			state.isModal = modal;
		},
	},
});

export const { setGlobalModal } = modalSlice.actions;
export default modalSlice.reducer;
