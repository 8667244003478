import { ThemeContext } from '@almbrand/themeselector';
import { AdvancedMarker, Map, MapMouseEvent, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { exception } from 'services/ApplicationInsightsLog';
import { useAppSelector } from 'store/hooks';
import { AutoCompleteInput, ClaimLocation } from '../AutoCompleteInput';
import styles from './MapController.module.scss';

export interface MapControllerProps {
	fieldName: string;
	placeholder?: string;
	locationIcon?: string;
	allowSkip?: boolean;
	allowSkipText?: string;
	description?: string;
	descriptionMode?: string;
}

export interface MapsProposal {
	lat: number;
	lng: number;
	formattedAddress?: string;
}

export const centreOfDenmark = { lat: 55.6372497, lng: 10.9314442 };
export const unKnownLocation = { lat: 55.6372497, lng: 10.9314443 };

export const isDefaultLocation = (latLng: ClaimLocation): boolean => {
	return latLng.latitude === centreOfDenmark.lat && latLng.longitude === centreOfDenmark.lng;
};

export const MapController = ({
	fieldName,
	placeholder,
	locationIcon,
	allowSkip,
	allowSkipText,
	description,
	descriptionMode,
}: MapControllerProps) => {
	const { setValue } = useFormContext(); // retrieve all hook methods

	const fieldWatch = useAppSelector((store) => store.claimForm[fieldName]);

	const theme = useContext(ThemeContext);

	useEffect(() => {
		const location = fieldWatch?.data;
		if (location) {
			handleInputAddress(location);
		}
	}, [fieldWatch]);

	const map = useMap();
	const geocodingLib = useMapsLibrary('geocoding');

	const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral>(centreOfDenmark);
	const [selectedPlace, setSelectedPlace] = useState<ClaimLocation>();

	const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);

	const reverseGeocoding = (location: google.maps.LatLngLiteral): Promise<ClaimLocation | null> => {
		return new Promise((resolve, reject) => {
			if (!geocoder) {
				const err = new Error('Geocoder is not initialized');
				exception(err);
				return reject(err);
			}

			geocoder.geocode({ location }, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK && results?.[0]) {
					resolve(extractPlaceInfo(results[0], location));
				} else if (status !== google.maps.GeocoderStatus.OK) {
					exception(new Error('Geocoder failed due to: ' + status));
					reject(new Error('Geocoder failed'));
				} else {
					resolve(null);
				}
			});
		});
	};

	const getMapsProposal = async (address: string): Promise<MapsProposal> => {
		return new Promise((resolve) => {
			geocoder?.geocode({ address }, async (results, status) => {
				if (status == google.maps.GeocoderStatus.OK && results?.[0]) {
					const lat = results[0].geometry?.location?.lat();
					const lng = results[0].geometry?.location?.lng();
					const formattedAddress = results[0].formatted_address;
					if (lat && lng) {
						resolve({ lat, lng, formattedAddress });
					}
				}
				resolve({ ...centreOfDenmark, ...{ formattedAddress: undefined } });
			});
		});
	};

	const handleInputAddress = (location: ClaimLocation | null) => {
		if (!location) {
			exception(new Error('location error'));
			return;
		}
		setSelectedPlace(location);

		setMarkerPosition({ lat: location.latitude, lng: location.longitude });

		setValue(fieldName, !isDefaultLocation(location) ? location : undefined, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	function extractPlaceInfo(
		place: google.maps.GeocoderResult,
		location: google.maps.LatLngLiteral
	): ClaimLocation | null {
		let country = '';
		let countryCode = '';
		// Extract country information from address components
		place.address_components.forEach((component) => {
			if (component.types.includes('country')) {
				country = component.long_name;
				countryCode = component.short_name;
			}
		});

		const claimLocation = {
			value: place.formatted_address || '',
			latitude: location.lat,
			longitude: location.lng,
			country: country,
			countryCode: countryCode,
		};

		return claimLocation;
	}

	const handleMarkerChange = async (event: MapMouseEvent) => {
		const { latLng } = event?.detail ?? {};
		if (!latLng) {
			exception(new Error('event.detail latLng is null'));
			return;
		}
		setMarkerPosition(latLng);

		try {
			const location = await reverseGeocoding(latLng);

			if (!location) {
				exception(new Error('location error'));
				return;
			}
			setSelectedPlace(location);

			setValue(fieldName, location, {
				shouldValidate: true,
				shouldDirty: true,
			});
		} catch (error) {
			exception(error as Error, 'Error in reverseGeocoding');
		}
	};

	useEffect(() => {
		if (!map || !selectedPlace) return;
		const location = new google.maps.LatLng(selectedPlace.latitude, selectedPlace.longitude);
		map.setCenter(location);
	}, [map, selectedPlace]);

	return (
		<>
			{/* {descriptionMode === 'Tooltip' && description && (
				<Tooltip
					icon={{
						iconProp: { themeName: theme },
					}}
					align='right'
					htmlContent={description}
				/>
			)} */}
			<AutoCompleteInput
				setSelectedPlace={handleInputAddress}
				selectedPlace={selectedPlace?.value}
				fieldName={fieldName}
				locationIcon={locationIcon}
				placeholder={placeholder}
				defaultLocation={centreOfDenmark}
				getMapsProposal={getMapsProposal}
				allowSkip={allowSkip}
				allowSkipText={allowSkipText}
			/>
			<div className={styles.MapController__map}>
				<Map
					defaultCenter={centreOfDenmark}
					defaultZoom={9}
					streetViewControl={false}
					fullscreenControl={false}
					zoomControl={false}
					mapTypeControl={false}
					onClick={handleMarkerChange}
					mapId={fieldName}
					style={{ borderRadius: '1rem', overflow: 'hidden' }}
				>
					<AdvancedMarker position={markerPosition} />
				</Map>
			</div>
		</>
	);
};
