import { ThemeContext } from '@almbrand/themeselector';
import { ContentContainer, Usabilla } from 'components';
import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearForm } from 'store/slices/claimFormSlice';
import { clearPages } from 'store/slices/pageListSlice';
import { removeStateFromCookie } from 'utilities/cookieUtils';
import { removeStateFromSessionStorage } from 'utilities/sessionStorageUtils';
import styles from './ResultPage.module.scss';

export interface ResultPageProps {}

export const ResultPage: React.FC<ResultPageProps> = () => {
	const [resultPage, setResultPage] = useState<string>();
	const result = useAppSelector((state) => state.pages.resultPageHTML);
	const dispatch = useAppDispatch();

	const claimContent = useAppSelector((state) => state.claimContent);
	const { usabilla_id } = claimContent.wizardData ?? {};
	const theme = useContext(ThemeContext);

	useEffect(() => {
		if (!result) return;
		removeStateFromSessionStorage();
		removeStateFromCookie();
		dispatch(clearForm());
		setResultPage(result);
		dispatch(clearPages());
	}, [result]);

	return (
		<section className={styles.ResultPage}>
			<ContentContainer children={resultPage} />
			{usabilla_id && (
				<Usabilla
					stepId='Fnol_receipt'
					usabillaWidgetId={usabilla_id}
					category={'ABG_Fnol_receipt_' + theme}
				/>
			)}
		</section>
	);
};
