import { ThemeSelector } from '@almbrand/themeselector';
import { Route, Routes } from 'react-router-dom';
import styles from './App.module.scss';

import { initAppInsightsLogger } from 'services/ApplicationInsightsLog';
import { loadUsabillaScript } from 'services/UsabillaService';
import { PageMapper } from './components/PageMapper';
import { useAppSelector } from './store';

const App = () => {
	initAppInsightsLogger();
	// This script should be loaded from googleTag manager or CMS , when we are setup
	loadUsabillaScript();

	const themeName = useAppSelector((state) => state.claimContent.theme);
	return (
		<ThemeSelector themeName={themeName == 'codan' ? 'Codan-theme' : 'Almbrand-theme'}>
			<div className={styles.App}>
				<Routes>
					<Route path='/'>
						<Route
							path=':flowName'
							element={<PageMapper />}
						/>
					</Route>
				</Routes>
			</div>
		</ThemeSelector>
	);
};

export default App;
