import { Button } from '@almbrand/button';
import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { Header as HeaderComponentLibrary } from '@almbrand/header';
import classNames from 'classnames';
import { PageNames } from 'constants/pageNames';
import { usePageParam } from 'hooks/usePageParam';
import { useToggleClassOnScoll } from 'hooks/useToggleClassOnScoll';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setGlobalCssClassState } from 'store/slices/addCssClassSlice';
import style from './Header.module.scss';
import codanLogo from './assets/CodanLogo.svg';

export type HeaderLink = {
	action: () => void;
	title: string;
};

export interface HeaderProps {
	onLogoutClick: () => void;
	navLinks: HeaderLink[];
	logoLink: string;
}

export const Header: React.FC<HeaderProps> = ({ onLogoutClick, navLinks, logoLink }) => {
	const { setPageParam } = usePageParam();
	const containerRef = useRef<HTMLDivElement>(null);

	const addClass = useToggleClassOnScoll(10);

	const dispatch = useAppDispatch();

	dispatch(setGlobalCssClassState(addClass));

	const isUserLoggedIn = useAppSelector((state) => state.customerParty?.data?.isUserLoggedIn);

	const buttonText = isUserLoggedIn ? 'Log ud' : 'Log ind';
	const onClickHandler = () => {
		isUserLoggedIn ? onLogoutClick() : setPageParam(PageNames.LoginPage);
	};

	return (
		<Container
			backgroundColor='bg-white'
			desktopWidth='fullWidth'
			mobileWidth='fullWidth'
			tabletWidth='fullWidth'
			align='none'
			className={classNames(style.Header, addClass ? style.Header___hide : null)}
		>
			<Grid ref={containerRef}>
				<GridCell
					desktopWidth='12col'
					tabletWidth='12col'
					mobileWidth='12col'
				>
					<HeaderComponentLibrary
						logoUrl={codanLogo}
						logoLink={logoLink}
						navigationLinks={navLinks}
						renderChildren={(link: HeaderLink) => (
							<Button
								onClick={() => link.action()}
								children={link.title}
								key={link.title}
								buttonType='link'
								size='small'
							/>
						)}
						buttonProps={{
							children: buttonText,
							onClick: () => onClickHandler(),
						}}
					/>
				</GridCell>
			</Grid>
		</Container>
	);
};
