import { GridCell } from '@almbrand/grid';
import { ProgressBar } from 'components/1-atoms';
import { TransitionContainer } from 'components/4-habitats';
import { PageTemplate } from 'components/5-templates/PageTemplate';
import { ProgressBarNames } from 'constants/progressBarNames';
import { usePageParam } from 'hooks/usePageParam';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styles from './MultiplePageTemplate.module.scss';

export interface MultiplePageTemplateProps {}

export const MultiplePageTemplate: React.FC<MultiplePageTemplateProps> = () => {
	const activePages = useAppSelector((state) => state.pages.activePages);

	const { getPageParam } = usePageParam();

	const [animateIndex, setAnimateIndex] = useState<number>(-1);

	/**
	 * triggered on page query parameter
	 * handles whether to animate showing a question - scroll from top to bottom
	 */
	const handlePageNavigation = () => {
		const currenPath = getPageParam();
		const formattedSearchString = `/${currenPath}`;

		// check if pageContent is in the activePages list
		const indexOfCurrentPage = activePages
			.map((page) => {
				return page.path;
			})
			.indexOf(formattedSearchString);

		// animate when next button is pressed on an existing page ( user has gone back in flow and pressed "Next" )
		if (indexOfCurrentPage > -1 && indexOfCurrentPage < activePages.length) {
			// scroll to an existing page
			setAnimateIndex(indexOfCurrentPage);
		} else {
			// new page added, always animate on a new page
			setAnimateIndex(activePages.length);
		}
	};

	useEffect(() => {
		handlePageNavigation();
	}, [getPageParam()]);

	return (
		<>
			<GridCell
				desktopWidth='9col'
				className={styles.MultiplePageTemplate}
			>
				{activePages?.map((page: WizardPage, index: number) => {
					if (ProgressBarNames[page?.pageCategories?.value as keyof typeof ProgressBarNames]) {
						return (
							<TransitionContainer
								key={page.id}
								animate={index === animateIndex}
							>
								<PageTemplate pageData={page} />
							</TransitionContainer>
						);
					}
				})}
			</GridCell>
			<GridCell
				desktopWidth='3col'
				className={styles.MultiplePageTemplate_progressBarWrapper}
			>
				<ProgressBar
					activePages={activePages}
					setActivePage={(index) => {
						setAnimateIndex(index);
					}}
				/>
			</GridCell>
		</>
	);
};
