import { Modal } from '@almbrand/modal';
import { useAutoLogoutTimer } from 'hooks/useAutoLogoutTimer';
import { useAppSelector } from 'store/hooks';
import { removeStateFromCookie } from 'utilities/cookieUtils';
import { removeStateFromSessionStorage } from 'utilities/sessionStorageUtils';

export interface LeaveFlowModalProps {
	setModalOpen: (open: boolean) => void;
	triggerLogout: boolean;
	requestedUrl: string;
	isAutoLogout?: boolean;
}

export const LeaveFlowModal: React.FC<LeaveFlowModalProps> = ({
	setModalOpen,
	triggerLogout,
	requestedUrl,
	isAutoLogout,
}) => {
	const claimContent = useAppSelector((state) => state.claimContent);
	const { applicationId } = claimContent?.wizardData?.mitIdConfig?.almbrand ?? {};

	/**
	 * User want's to leave the claim flow, so delete current claim flow and redirect to main website.
	 */
	const logout = async () => {
		// FIXME: get the redirectUri from the cms
		const logoutUrl = `/auth/logout?applicationId=${applicationId}&redirectUri=${requestedUrl}`;
		removeStateFromSessionStorage();
		removeStateFromCookie();
		window.location.href = logoutUrl;
	};

	const handleLeaveFlow = () => {
		// FIXME: get the redirectUri from the cms

		if (triggerLogout) {
			logout();
		} else {
			window.location.href = requestedUrl;
		}
	};

	/**
	 * Handel auto logout if the usere have been inactive for to lognf
	 */
	const { triggerAutoLogout, triggerTimer } = useAutoLogoutTimer(60000); //1 min before auto logout

	if (isAutoLogout) {
		triggerTimer();
	}

	if (triggerAutoLogout) {
		logout();
	}
	/************************************************************ */

	return (
		<Modal
			title='Er du sikker på, at du vil annullere din anmeldelse?'
			children={
				<>
					Hvis du annullere din skade, skal du være opmærksom på, at du skal starte forfra når/hvis du
					anmelder skaden på et senere tidspunkt.
				</>
			}
			closeText='Færdiggør anmeldelse'
			actionText='Annuller anmeldelse'
			ariaClose='Close'
			handleClose={() => setModalOpen(false)}
			handleAction={handleLeaveFlow}
			actionButtonType='tertiary'
			closeButtonType='primary'
			reverseButtonOrder={true}
		/>
	);
};
